<template>
    <div>
        <div class="detail-title">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>教研中心</el-breadcrumb-item>
                <el-breadcrumb-item
                    :to="{ path: '/evaluation/details/' + $route.params.id + '/scoreConfiguration/' + $route.params.classId }">评分配置</el-breadcrumb-item>
                <el-breadcrumb-item>编辑</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="detail-info">
            <el-form ref="form" :model="form" :rules="rules" label-width="180px" size="mini" v-loading="boxLoading">
                <el-form-item label="类型：" label-position="left" class="input" prop="type">
                    <el-select v-model="form.type" placeholder="请选择类型">
                        <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>


                <el-form-item label="最低得分：" label-position="left" class="input" prop="min_score">
                    <el-input-number v-model="form.min_score" :min="0" label="最低得分"></el-input-number>
                </el-form-item>


                <el-form-item label="最高得分：" label-position="left" class="input" prop="max_score">
                    <el-input-number v-model="form.max_score" :min="0" label="最高得分"></el-input-number>
                </el-form-item>




                <el-form-item label="标签：" label-position="left" class="input" prop="tag">
                    <el-input placeholder="请输入标签" v-model="form.tag">
                    </el-input>
                </el-form-item>

                <el-form-item label="建议：" label-position="left" prop="suggest">
                    <el-input type="textarea" :rows="2" style="width: 300px;" placeholder="请输入内容" v-model="form.suggest">
                    </el-input>
                </el-form-item>





                <el-form-item>
                    <el-button size="small" type="primary" :loading="loading" @click="checkOnSubmit">保存</el-button>
                    <el-button style="margin-left: 10px"
                        @click="$router.push('/evaluation/details/' + $route.params.id + '/scoreConfiguration/' + $route.params.classId)"
                        size="small">取消</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
export default {

    name: 'Add',

    data() {
        return {
            form: {
                min_score: 0,
                max_score: 0
            },
            rules: {
                type: [
                    { required: true, message: '请选择类型', trigger: 'change' }
                ],
                min_score: [
                    { required: true, message: '请输入最低得分', trigger: 'blur' }
                ],
                max_score: [
                    { required: true, message: '请输入最高得分', trigger: 'blur' }
                ],
                suggest: [
                    { required: true, message: '请输入建议', trigger: 'blur' }
                ],
                tag: [
                    { required: true, message: '请输入标签', trigger: 'blur' }
                ],
            },
            loading: false,
            options: [
                { id: 1, name: '自主阅读' },
                { id: 2, name: '综合理解' },
                { id: 3, name: '阅读速度' },
                { id: 4, name: '信息提取' },
                { id: 5, name: '潜在信推断' }
            ],
            boxLoading:false,
        }
    },
    methods: {
        ...mapActions('evaluation', ['addEvaluationItemScore','getEvaluationItemScore']),
        checkOnSubmit() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.add()
                }
            });
        },

        async add() {
            this.loading = true
            const { res_info } = await this.addEvaluationItemScore(this.form)
            this.loading = false
            if (res_info != 'ok') return
            this.$message.success('编辑成功！')
            this.$router.push('/evaluation/details/' + this.$route.params.id + '/scoreConfiguration/' + this.$route.params.classId)
        },
        async init() {
            this.boxLoading = true
            const { data } = await this.getEvaluationItemScore({ id: this.form.id })
            this.form = data
            this.boxLoading = false
        },

    },
    mounted() {
        this.form.id = this.$route.params.scoreConfigurationId;
        this.form.evaluation_items_id = this.$route.params.classId;
        this.init()
    },
}
</script>


